<template>
	<Modal
		title="分配角色"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<CheckboxGroup v-model="selectRoleList">
			<div v-for="item in roleList" :key="item.id" class="role-list-item">
				<Checkbox :label="item.id">
					<span>{{ item.name }}</span>
				</Checkbox>
			</div>
		</CheckboxGroup>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/staff"

const { apiGetRoleList, apiSetRoleList } = api

export default {
	name: "Role",
	props: ["visible", "id"],
	data() {
		return {
			loading: false,
			roleList: [], // 所有角色列表
			roleIdList: [], // 所有的角色列表的Id
			selectRoleList: [] // 选中的角色列表的Id
		}
	},
	watch: {
		visible() {
			if (this.visible && this.id) {
				this.$asyncDo(async () => {
					this.loading = true
					const res = await apiGetRoleList({ id: this.id })
					if (res) {
						this.roleList = res.data
						this.roleIdList = res.data.map(item => item.id)
						this.selectRoleList = res.data
							.filter(item => item.checked)
							.map(item => item.id)
					}
					this.loading = false
				})
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiSetRoleList({
					roleIds: this.selectRoleList,
					id: this.id
				})
				if (res) {
					this.$emit("onOk")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			})
		}
	}
}
</script>

<style scoped>
.role-list-item {
	margin-bottom: 15px;
	padding-left: 15px;
}
</style>
