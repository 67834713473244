<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="handleSearch" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="用户名">
				<Input placeholder="请输入用户名" v-model="searchParams.username" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="真实姓名">
				<Input placeholder="请输入真实姓名" v-model="searchParams.realName" />
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="状态">
				<Select
					v-model="searchParams.isDel"
					:transfer="true"
					placeholder="请选择状态"
					clearable
				>
					<Option :value="0">启用</Option>
					<Option :value="1">禁用</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				@click="handleAddShow"
				v-if="!(systemActionPermissions.indexOf('btn_setting_user_add') === -1)"
				>新增</Button
			>
			<Button @click="handleExportTemplate">下载导入模板</Button>
			<Upload
				ref="uploadStaff"
				:headers="{ token: $store.state.user.token }"
				:show-upload-list="false"
				accept=".xlsx"
				:before-upload="checkBeforeUpload"
				:on-success="onSuccess"
				:on-error="onError"
				:data="extraData"
				:action="`${$baseUrl}/auth/users/import-excel`"
				style="display: inline-block"
			>
				<Button :loading="uploadLoading">上传文件</Button>
			</Upload>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:jobList="dictionaryList[7]"
			:sexList="dictionaryList[5]"
			:organizationList="organizationList"
			:id="updateId"
		></Add>
		<ViewDetail
			:visible="viewVisible"
			:organizationList="organizationList"
			@onCancel="viewVisible = false"
			:id="viewId"
		></ViewDetail>
		<ResetPassword
			:visible="resetPasswordVisible"
			@onCancel="resetPasswordVisible = false"
			@onOk="handleResetPasswordOk"
			:id="resetPasswordId"
		></ResetPassword>
		<Role
			:visible="roleVisible"
			@onCancel="roleVisible = false"
			@onOk="handleRoleOk"
			:id="roleId"
		></Role>
		<Resume
			:visible="resumeVisible"
			@onCancel="resumeVisible = false"
			:id="updateId"
		></Resume>
	</div>
</template>

<script>
import api from "@/api/setting/staff"
import publicApi from "@/api/public"
import { mapState } from "vuex"
import { formDataToMD5 } from "@/utils/util"
import Add from "./Add.vue"
import ViewDetail from "./ViewDetail.vue"
import ResetPassword from "./ResetPassword.vue"
import Resume from "./Resume.vue"
import Role from "./Role.vue"

const { apiGetPage, apiDelete } = api

const { publicGetDictionaryList, publicGetOrganizationList } = publicApi

const columns = [
	{
		title: "用户名",
		key: "username",
		minWidth: 200
	},
	{
		title: "真实姓名",
		key: "realName",
		minWidth: 200
	},
	{
		title: "排班负责人",
		key: "isSchedule",
		minWidth: 110,
		render: (h, params) => h("span", params.row.isSchedule ? "是" : "否")
	},
	{
		title: "性别",
		key: "sexName",
		minWidth: 80
	},
	{
		title: "手机号码",
		key: "phone",
		minWidth: 130
	},
	{
		title: "组织",
		key: "orgName",
		minWidth: 200
	},
	{
		title: "部门/科室",
		key: "deptName",
		minWidth: 150
	},
	{
		title: "职称",
		key: "jobName",
		minWidth: 130
	},
	{
		title: "CA编号",
		key: "caUuid",
		minWidth: 110
	},
	{
		title: "工号",
		key: "jobNumber",
		minWidth: 110
	}
]

export default {
	name: "index",
	components: {
		ViewDetail,
		Add,
		ResetPassword,
		Role,
		Resume
	},
	data() {
		return {
			listData: [],
			columns,
			actionColumn: {
				title: "操作",
				key: "action",
				width: 220,
				fixed: "right",
				renderButton: params => {
					const actionList = [
						{
							label: "查看",
							on: {
								click: this.handleViewShow
							}
						},
						{
							label: "修改",
							on: {
								click: this.handleUpdateShow
							}
						},
						{
							label: "分配角色",
							on: {
								click: this.handleRoleShow
							}
						},
						{
							label: "重置密码",
							on: {
								click: this.handleResetPasswordShow
							}
						},
						{
							label: "查看简历",
							on: {
								click: this.handleResume
							}
						},
						{
							label: params.row.isDel ? "启用" : "禁用",
							on: {
								click: this.handleSwitchState
							},
							confirm: {
								title: `您确认${params.row.isDel ? "启用" : "禁用"}这条数据吗？`
							}
						}
					]
					if (
						this.systemActionPermissions.indexOf("btn_setting_user_add") === -1
					) {
						actionList[1] = null // 修改
						actionList[2] = null // 分配角色
						actionList[5] = null // 启用/禁用
					}
					// 重置密码
					if (
						this.systemActionPermissions.indexOf("btn_setting_user_reset") ===
						-1
					) {
						actionList[3] = null
					}
					// 查看简历
					if (
						this.systemActionPermissions.indexOf("btn_setting_user_vc_view") ===
						-1
					) {
						actionList[4] = null
					}
					return actionList.filter(item => !!item)
				}
			},
			loading: false,
			// 新增/修改
			addVisible: false,
			updateId: "",
			// 查看
			viewVisible: false,
			viewId: "",
			// 重置密码
			resetPasswordVisible: false,
			resetPasswordId: "",
			// 分配角色
			roleVisible: false,
			roleId: "",
			// 查看简历
			resumeVisible: false,
			resumeId: "",
			// 数字签名
			signVisible: false,
			signId: "",
			// 分页
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				username: "",
				realName: "",
				isDel: 0
			},
			oldSearchParams: {
				username: "",
				realName: "",
				isDel: 0
			},
			// 数据字典
			dictionaryList: [],
			// 组织列表
			organizationList: [],
			extraData: {
				md5: ""
			},
			uploadLoading: false
		}
	},
	created() {
		this.initList()
		this.getPublicData()
		this.columns = [...columns, this.actionColumn]
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	methods: {
		getPublicData() {
			// 获取数据字典
			this.$asyncDo(async () => {
				const res = await publicGetDictionaryList()
				if (res) {
					this.dictionaryList = res.data
				}
			})
			// 获取组织列表
			this.$asyncDo(async () => {
				const res = await publicGetOrganizationList()
				if (res) {
					this.organizationList = res.data
				}
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					...this.oldSearchParams
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 搜索功能
		handleSearch() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.updateId = ""
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		// 修改显示
		handleUpdateShow({ row }) {
			this.addVisible = true
			this.updateId = row.id
		},
		// 分配角色显示
		handleRoleShow({ row }) {
			this.roleVisible = true
			this.roleId = row.id
		},
		// 启用/禁用
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiDelete({ id: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 页码改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				username: "",
				realName: "",
				isDel: 0
			}
			this.page.current = 1
			this.$nextTick(() => {
				this.handleSearch()
			})
		},
		// 查看显示
		handleViewShow({ row }) {
			this.viewId = row.id
			this.viewVisible = true
		},
		// 重置密码显示
		handleResetPasswordShow({ row }) {
			this.resetPasswordId = row.id
			this.resetPasswordVisible = true
		},
		// 重置密码成功
		handleResetPasswordOk() {
			this.resetPasswordVisible = false
		},
		// 查看个人简历
		handleResume({ row }) {
			this.updateId = row.id
			this.resumeVisible = true
		},
		// 分配角色成功
		handleRoleOk() {
			this.roleVisible = false
		},
		// 下载导入模板
		handleExportTemplate() {
			window.open(
				`${this.$baseUrl}/auth/users/export-excel-template?token=${this.$store.state.user.token}`,
				"_blank"
			)
		},
		// 进行查重
		checkBeforeUpload(file) {
			this.uploadLoading = true
			formDataToMD5(file, md5Str => {
				this.extraData.md5 = md5Str
				// 获取md5后进行文件上传
				this.$refs.uploadStaff.post(file)
			})
			return false
		},
		// 文件上传成功后
		onSuccess(res) {
			if (res.code !== 0) {
				this.$Message.error(res.message)
			} else {
				this.$Message.success("上传成功!")
				this.initList()
			}
			this.uploadLoading = false
		},
		onError(error) {
			this.$Message.error(error)
			this.uploadLoading = false
		}
	}
}
</script>
