<template>
	<Modal
		title="重置密码"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="密码" prop="password">
						<Input
							v-model.trim="formData.password"
							type="password"
							autocomplete="off"
							placeholder="请输入密码"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="确认密码" prop="confirmPassword">
						<Input
							v-model.trim="formData.confirmPassword"
							autocomplete="off"
							type="password"
							placeholder="请输入确认密码"
						/>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/staff"
import { dataToSha } from "@/utils/util"

const { apiResetPwd } = api

export default {
	name: "ResetPassword",
	props: {
		visible: {
			default: false
		},
		id: {
			default: ""
		}
	},
	data() {
		return {
			loading: false,
			formData: {
				password: "",
				confirmPassword: ""
			},
			rules: {
				password: [
					{ required: true, message: "密码不能为空" },
					{
						validator: "regexp",
						pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,16}$/,
						message: "密码为6-16位，需要同时包含大、小写字母与数字",
						trigger: "blur"
					},
					{ validator: this.validatePass, trigger: "blur" }
				],
				confirmPassword: [
					{ required: true, message: "确认密码不能为空" },
					{
						validator: "regexp",
						pattern: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{6,16}$/,
						message: "密码为6-16位，需要同时包含大、小写字母与数字",
						trigger: "blur"
					},
					{ validator: this.validatePassRepeat, trigger: "blur" }
				]
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
			}
		}
	},
	methods: {
		validatePass(rule, value, callback) {
			// 验证密码
			if (value === "") {
				callback(new Error("请输入密码"))
			} else {
				if (this.formData.confirmPassword !== "") {
					this.$refs.form.validateField("confirmPassword")
				}
				callback()
			}
		},
		validatePassRepeat(rule, value, callback) {
			// 验证确认密码
			if (value === "") {
				callback(new Error("请再次输入密码"))
			} else if (value !== this.formData.password) {
				callback(new Error("两次输入密码不一致!"))
			} else {
				callback()
			}
		},
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiResetPwd({
							id: this.id,
							password: dataToSha(this.formData.password)
						})
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
			return true
		}
	}
}
</script>

<style scoped></style>
