<template>
	<Modal
		class="resume-modal"
		:value="visible"
		width="1000"
		:fullscreen="fullscreen"
		:scrollable="true"
		:mask-closable="false"
		:footer-hide="true"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="modal-header">
			<h2>{{ title }}</h2>
			<a
				href="javascript:;"
				class="fullscreen"
				@click="fullscreen = !fullscreen"
			>
				<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
					<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
					<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
				</Tooltip>
			</a>
		</div>
		<div v-if="visible" :style="`height: ${gridHeight}px;overflow-y:auto;`">
			<ResumeInfo entry="view" :id="id"></ResumeInfo>
		</div>
	</Modal>
</template>

<script>
import ResumeInfo from "@/views/Personal/Info/Index.vue"
import { mapState } from "vuex"

export default {
	name: "resume",
	components: {
		ResumeInfo
	},
	props: {
		visible: {},
		id: {}
	},
	data() {
		return {
			loading: false,
			fullscreen: false,
			title: "查看简历"
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 100
		}),
		gridHeight() {
			return this.fullscreen
				? document.body.clientHeight - 100
				: this.dataGridHeight
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
			this.fullscreen = false
		}
	}
}
</script>

<style lang="less">
.resume-modal {
	/*margin-bottom: 20px;*/
	.ivu-modal-body {
		padding: 0;
	}
	.ivu-select-input[disabled],
	.ivu-input[disabled],
	.ivu-input-number-input[disabled] {
		color: #333;
	}
	.ivu-input-number-input::placeholder {
		color: #c5c8ce;
	}

	.modal-header {
		overflow: hidden;

		h2 {
			float: left;
		}

		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}

		.iconfont {
			font-size: 20px;
		}
	}
}
.gray-font {
	&::placeholder {
		color: #ccc;
	}
}
</style>
